import React, { useState, useEffect, useCallback } from 'react'
import './App.css';
import Lego from './Lego.js'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from './ScrollToTop';

const App = props => {

    let baseUrl = '';
    if (process.env.NODE_ENV === 'development') {
        baseUrl = 'https://meredithbashert.com/legos-backend/legos'
    } else {
        baseUrl = 'https://meredithbashert.com/legos-backend/legos'
    }


    const importAll = (r) => {
        let images = {};
        r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
        return images
    }
    const images = importAll(require.context('./assets', false, /\.(png|jpe?g|svg)$/));




    const[allLegos, setAllLegos] = useState([])
    const [seriesFilter, setSeriesFilter] = useState('')
    const[nameFilter, setNameFilter] = useState('')
    const [checked, setChecked] = useState(false)


    const handleDropdown = (e) => {
        setSeriesFilter(e.target.value)
    }

    const handleChange = (e) => {
        setNameFilter(e.target.value)
        setSeriesFilter('')
        setChecked(false)
    }

    const handleChecked = () => {
        setChecked(!checked)
        setSeriesFilter()
    }

    const clear = () => {
        setSeriesFilter('')
        setNameFilter('')
        setChecked(false)
    }


    const fetchLegos = useCallback(async () => {
        await fetch(`${baseUrl}/legos`)
        .then(data => data.json())
        .then(jData => {
            setAllLegos(jData)
        }).catch(err=>console.log(err))
    }, [])



    useEffect(() => {
        fetchLegos()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    return (
        <div className="App">
            <ScrollToTop />
            <div id='headline'>
                {checked?
                    <p><span id="cross-through">All the Legos</span> Just My Legos</p>
                    :
                    seriesFilter ?
                    <p>Series {seriesFilter}</p>
                    :
                    nameFilter ?
                    <p>"{nameFilter}"</p>
                    :
                    <p>All the Legos</p>

                }
            </div>


<div id ='toolbar'>
    <div id='filters'>
        <div>
            <label>Filter By Series:</label>
            <select value={seriesFilter} onChange={handleDropdown}>
                <option value="">All</option>
                <option value="1">Series 1</option>
                <option value="2">Series 2</option>
                <option value="3">Series 3</option>
                <option value="4">Series 4</option>
                <option value="5">Series 5</option>
                <option value="6">Series 6</option>
                <option value="7">Series 7</option>
                <option value="8">Series 8</option>
                <option value="9">Series 9</option>
                <option value="10">Series 10</option>
                <option value="11">Series 11</option>
                <option value="12">Series 12</option>
                <option value="13">Series 13</option>
                <option value="14">Series 14</option>
                <option value="15">Series 15</option>
                <option value="16">Series 16</option>
                <option value="17">Series 17</option>
                <option value="18">Series 18</option>
                <option value="19">Series 19</option>
                <option value="20">Series 20</option>
                <option value="21">Series 21</option>
                <option value="22">Series 22</option>
                <option value="23">Series 23</option>
                <option value="24">Series 24</option>
            </select>
        </div>



        <div id='my_legos'>
            <label htmlFor="checked">My Legos:</label>

                <input type = "checkbox"
                    onChange={handleChecked}
                    checked={checked}
                    id='owned-checkbox'/>


        </div>

        <button onClick={() => clear()} >Clear Filters</button>

    </div>

    <div id='search'>
        <label htmlFor="search_box">Search</label>
        <input
            type="text"
            id="search_box"
            value={nameFilter}
            onChange={handleChange}
            />
        <button onClick={() => clear()} >X</button>
    </div>


</div>


            <br/>

            {seriesFilter  ?
                <div className = "all-legos">
                    {allLegos.filter(oneLego => {return oneLego.series === seriesFilter})
                    .map((oneLego) => {
                        return (

                            <div className = 'lego' key={oneLego.id}>
                                <Lego
                                    oneLego={oneLego}
                                    legoImage={images[`${oneLego.name}.jpg`]}
                                    baseUrl={baseUrl}
                                    key={oneLego.id}
                                    />

                            </div>

                        )
                    })}
                </div>

                :
                checked ?
                <div className = "all-legos">
                    {allLegos.filter(oneLego => {return oneLego.owned==='t'})
                    .map((oneLego) => {
                        return (

                            <div className = 'lego' key={oneLego.id}>
                                <Lego
                                    oneLego={oneLego}
                                    legoImage={images[`${oneLego.name}.jpg`]}
                                    baseUrl={baseUrl}
                                    key={oneLego.id}
                                    />

                            </div>

                        )
                    })}
                </div>
                :
                nameFilter ?
                <div className = "all-legos">


                    {allLegos.filter(oneLego => {
                        return oneLego.name
                    }).filter(oneLego => oneLego.name.toLowerCase().includes(nameFilter.toLowerCase()))
                    .map((oneLego) => {
                        return (
                            <div className = 'lego'>
                                <Lego
                                    oneLego={oneLego}
                                    legoImage={images[`${oneLego.name}.jpg`]}
                                    baseUrl={baseUrl}
                                    key={oneLego.id}
                                    />

                            </div>
                        )

                    })
                }
            </div>
            :
            <div className = "all-legos">

                {allLegos.map((oneLego) => {
                    return (
                        <div className = 'lego'>
                            <Lego
                                oneLego={oneLego}
                                legoImage={images[`${oneLego.name}.jpg`]}
                                baseUrl={baseUrl}
                                key={oneLego.id}
                                fetchLegos={fetchLegos}
                                />

                        </div>

                    )
                })}
            </div>
        }

    </div>
);
}


export default App
