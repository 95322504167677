import React, {useState, useEffect} from 'react'
import './Lego.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';




const Lego = props => {

    const [show, setShow] = useState(false);



    const handleClose = (e) => {
        setShow(false)
    }


    const handleSubmitTrue = (e) => {
        e.preventDefault()
        const legoinfo = {
            name:props.oneLego.name,
            year:props.oneLego.year,
            series:props.oneLego.series,
            owned:true,
            id:props.oneLego.id
        }
        handleUpdateLego(legoinfo)
        handleClose()
        props.fetchLegos()
    }

    const handleSubmitFalse = (e) => {
        e.preventDefault()
        const legoinfo = {
            name:props.oneLego.name,
            year:props.oneLego.year,
            series:props.oneLego.series,
            owned:"f",
            id:props.oneLego.id
        }
        handleUpdateLego(legoinfo)
        handleClose()
        props.fetchLegos()
    }

    const handleUpdateLego = (updateData) => {
        fetch(`http://localhost:8888/legos/${updateData.id}`, {
            body: JSON.stringify(updateData),
            method: 'PUT',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            }
        }).then(updatedLego => {
            props.fetchLegos()
        }).catch(err=>console.log(err))
    }

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (

        <div className = 'lego'  key={props.oneLego.id}>

            <div id='container'>
                <img src={props.legoImage} className = 'lego-pic' alt='lego-image' onClick={setShow}/>
                <div id="plus">{props.oneLego.owned==='f' ?
                    <form onSubmit={handleSubmitTrue}><Button type = "submit" variant="warning" id='add'>+</Button></form>
                    :
                    null
                }</div>
            </div>
            <br/>
            <h5>
                {props.oneLego.name}
                <br/>
                Series {props.oneLego.series}
                <br/>
                {props.oneLego.year}
                <br/>
            </h5>
            <h4>
                {props.oneLego.owned==='t' ?
                    <p id='in_collection'>In Collection
                        <form onSubmit={handleSubmitFalse}>
                            <Button type = "submit" id='remove' variant="outline-dark">X</Button></form></p>
                            :
                            null
                        }</h4>

                        <Modal show={show} onHide={handleClose} style={ {backgroundColor: 'rgb(255, 255, 255, .5)'}}>
                            <Modal.Body>
                                <div id='lego-closeup'>

                                    <img src={props.legoImage} id='large-image'/>
                                    <h3>{props.oneLego.name}</h3>
                                    <h3>Series {props.oneLego.series}</h3>

                                    {props.oneLego.owned === 't'
                                        ?
                                        <h5>In Collection</h5>
                                        :
                                        null
                                    }


                                </div>
                            </Modal.Body>
                        </Modal>



                    </div>


                );
            }


            export default Lego
